
<template>
  <div>
    <course-list 
      is_component_use_in_student_access 
      :use_with_emit="true" 
      @courseDetails="handleCourseDetails"
    ></course-list>
    <dialog-show-course-details 
      :dialog="dialog"
      :course="selected_course_details"
      @closeDialog="handleCloseDialog"
    />
  </div>
</template>

<script>

export default {
  name: 'EnrolledCourses',
  components: {
    CourseList: () => import('../list/CourseList.vue'),
    DialogShowCourseDetails: () => import('../../candidate_select_done_forms/select_components/DialogShowCourseDetails.vue'),
  },
  data() {
    return {
      selected_course_details: {},
      dialog: false,
    }
  },
  methods: {
    handleCourseDetails(e){
      this.selected_course_details = e
      this.dialog = true
    },
    handleCloseDialog(){
      this.dialog = false
      this.selected_course_details = {}
    },
  },
}
</script>

<style scoped>
  >>> .v-dialog {
    overflow-x: hidden;
    border-radius: 18px;
  }
</style>